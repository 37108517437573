/*.div{*/
/*    text-align: right,*/
/*!* media queries *!*/
/*@media (max-width: 767px) {*/
/*    textAlign: center;*/
/*}*/

/*@media (max-width: 400px) {*/
/*    text-align: left;*/
/*}*/

@media (max-width: 700px) {
    .uid {
        display: none;
    }
}
